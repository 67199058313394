import React, { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';
import logo from './assets/logo.png';

const banner = `
    <div>
        <span class="banner-upper-text">•Welcome to my Nostalgic Command Shell•</span>
    </div>
    <div>
        <img src="${logo}" alt="Logo" style="width: 300px; height: 70px;" />
    </div>
    <div>
        <span class="banner-lower-text">Για τη λίστα Menu πληκτρολογήστε 'help'.</span>
    </div>
`;

const youtube = "https://youtube.com/@HellasDev";
const github = "https://github.com/HellasDev";
const instagram = "https://instagram.com/HellasDev";
const twitter = "https://twitter.com/HellasDev";
const donate = "https://www.buymeacoffee.com/HellasDev";
const archpackages = "https://github.com/HellasDev/ArchPackages";
const archmanagement = "https://github.com/HellasDev/ArchManagement";

const commandsList = {
    whois: [
        "<br>",
        "Είμαι ο Γιώργος - 💻Hellas Dev💻!",
        "<br>",
        "Δημιουργός περιεχομένου στην πλατφόρμα YouTube,",
        "<br>",
        "που απολαμβάνει να εξερευνά τον κόσμο της τεχνολογίας.",
        "<br>",
        "Με έμφαση στο MacOS, Linux και τον προγραμματισμό,",
        "<br>",
        "μέσω του καναλιού του, μοιράζεται πολύτιμες συμβουλές,",
        "<br>",
        "τεχνικές γνώσεις και πρακτικές συμβουλές.",
        "<br>",
        "Ακολουθήστε τον στα κοινωνικά δίκτυα",
        "<br>",
        `και επισκεφθείτε τα έργα του στο <a href="${github}" target="_blank">GitHub</a> ή`,
        "<br>",
        `πατήστε <a href="${youtube}" target="_blank">εγγραφή</a> για να μη χάσετε κανένα ενδιαφέρον βίντεο.`,
        "<br>"
    ],

    help: [
        "<br>",
        '<div class="help-row"><span class="command">whois</span><span class="description">Ποιος είναι ο HellasDev;</span></div>',
        '<div class="help-row"><span class="command">whoami</span><span class="description">Ποιος είσαι εσύ;</span></div>',
        '<div class="help-row"><span class="command">video</span><span class="description">Δείτε βίντεο μου στο YouTube</span></div>',
        '<div class="help-row"><span class="command">social</span><span class="description">Εμφάνιση κοινωνικών δικτύων</span></div>',
        '<div class="help-row"><span class="command">email</span><span class="description">Μην μου στέλνετε email</span></div>',
        '<div class="help-row"><span class="command">projects</span><span class="description">Δείτε τα έργα μου</span></div>',
        '<div class="help-row"><span class="command">donate</span><span class="description">Κέρνα έναν καφέ</span></div>',
        '<div class="help-row"><span class="command">history</span><span class="description">Δείτε το ιστορικό εντολών σας</span></div>',
        '<div class="help-row"><span class="command">clear</span><span class="description">Καθαρισμός τερματικού</span></div>',
        "<br>",
    ],

    whoami: [
        "<br>",
        "Το μυστήριο της αυτογνωσίας: Ποτέ δεν ξέρουμε πλήρως ποιοι είμαστε, καθώς ανακαλύπτουμε συνεχώς νέες πτυχές του εαυτού μας.",
        "<br>"
    ],

    social: [
        "<br>",
        '<div class="help-row"><span class="command"><i class="fab fa-youtube"></i> YouTube</span><span class="description" style="margin-left: 10px;"><a href="' + youtube + '" target="_blank">youtube/@HellasDev</a></span></div>',
        '<div class="help-row"><span class="command"><i class="fab fa-github"></i> GitHub</span><span class="description" style="margin-left: 10px;"><a href="' + github + '" target="_blank">github/HellasDev</a></span></div>',
        '<div class="help-row"><span class="command"><i class="fab fa-instagram"></i> Instagram</span><span class="description" style="margin-left: 10px;"><a href="' + instagram + '" target="_blank">instagram/HellasDev</a></span></div>',
        '<div class="help-row"><span class="command"><i class="fab fa-twitter"></i> Twitter</span><span class="description" style="margin-left: 10px;"><a href="' + twitter + '" target="_blank">twitter/HellasDev</a></span></div>',
        '<div class="help-row"><span class="command"><i class="fas fa-coffee"></i> Donate</span><span class="description" style="margin-left: 10px;"><a href="' + donate + '" target="_blank">Buy me a Coffee</a></span></div>',
        "<br>",
    ],
    projects: [
        "<br>",
        'Εξακολουθώ να διαμορφώνω νέα… τα περισσότερα έργα είναι εμπιστευτικά στο GitHub.',
        'Αλλά αν επιμένετε να δείτε μερικά, ρίξτε μια ματιά στους παρακάτω συνδέσμους.',
        '<div class="help-row"><span><i class="fab fa-github"></i> </span><span class="descriptions"><a href="' + github + '" target="_blank">GitHub</a></span></div>',
        '<div class="help-row"><span><i class="fab fa-github"></i> </span><span class="descriptions"><a href="' + archpackages + '" target="_blank">Συλλογή Arch-Packages</a></span></div>',
        '<div class="help-row"><span><i class="fab fa-github"></i> </span><span class="descriptions"><a href="' + archmanagement + '" target="_blank">Διαχείριση Συστήματος ArchLinux</a></span></div>',
        "<br>",
    ],
    
    
    
};

function App() {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState([]);
    const inputRef = useRef(null);
    const endOfOutputRef = useRef(null);
    const [commandHistory, setCommandHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [bannerDisplayed, setBannerDisplayed] = useState(false);

    const loopLines = useCallback((lines, style, time) => {
        lines.forEach((line, index) => {
            addLine(line, style, index * time);
        });
    }, []);
    
    useEffect(() => {
        if (!bannerDisplayed) {
            setBannerDisplayed(true);
            addLine(banner, "", 80);
        }
        inputRef.current.focus();
    }, [bannerDisplayed]);
    

    // Εδώ προσθέτουμε τον κώδικα για να εστιάζει το input όταν κάνουμε κλικ εκτός
    useEffect(() => {
        const handleClick = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                inputRef.current.focus();  // Επανεστιάζει το input όταν γίνει κλικ εκτός
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const handleInput = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleCommandInput();
        } else if (e.key === 'ArrowUp') {
            navigateHistory(-1);
        } else if (e.key === 'ArrowDown') {
            navigateHistory(1);
        }
    };

    const handleCommandInput = () => {
        const command = input.trim();
        if (command) {
            setCommandHistory(prev => [...prev, command]);
            setHistoryIndex(-1);
            addLine(`root@Hellas.Dev:~$ ${command}`, "no-animation", 0);
            commander(command.toLowerCase());
            setInput('');
        }
    };

    const navigateHistory = (direction) => {
        if (direction === -1 && historyIndex > 0) {
            setHistoryIndex(prev => prev - 1);
            setInput(commandHistory[historyIndex - 1]);
        } else if (direction === 1 && historyIndex < commandHistory.length - 1) {
            setHistoryIndex(prev => prev + 1);
            setInput(commandHistory[historyIndex + 1] || '');
        }
    };

    

const commander = (cmd) => {
    switch (cmd) {
        case "help":
            loopLines(commandsList.help, "color2 margin", 80);
            break;
        case "whois":
            loopLines(commandsList.whois, "color2 margin", 80);
            break;
        case "whoami":
            loopLines(commandsList.whoami, "color2 margin", 80);
            break;
        case "social":
            loopLines(commandsList.social, "color2 margin", 80);
            break;
        case "projects":
            loopLines(commandsList.projects, "color2 margin", 80);
            break;
        case "ls":
            addLine("HellDev-Home", "color2 margin", 80);
            break;
        case "ls -a":
            addLine("HellDev-Home secret-folder", "color2 margin", 80);
            break;
        case "cd secret-folder":
            addLine("Σε τσάκωσα γατάκι...", "color1 margin", 80);
            break;
        case "cd HellDev-Home":
            addLine("Που πας ρε καραμήτρο;", "color1 margin", 80);
            break;
        case "cd helldev-home":
            addLine("Που πας ρε καραμήτρο;", "color1 margin", 80);
            break;
        case "email":
            window.location.href = 'mailto:info@hellas.dev';
            addLine("Άνοιγμα του email client... (mailto:info@hellas.dev)", "color2 margin", 80);
            break;
        case "video":
            window.open(youtube);
            addLine("Άνοιγμα του καναλιού HellasDev στο YouTube...", "color2 margin", 80);
            break;
        case "donate":
            window.open(donate);
            addLine("Ευχαριστώ για την αγάπη σου!!!", "color2 margin", 80);
            break;
        case "clear":
            setOutput([]);
            break;
        case "banner":
            loopLines(banner, "", 80);
            break;
        case "history":
            if (commandHistory.length === 0) {
                addLine("Δεν υπάρχουν καταγεγραμμένες εντολές.", "color2 margin", 80);
            } else {
                commandHistory.forEach((cmd, index) => {
                    addLine(`${index + 1}: ${cmd}`, "color2 margin", 80);
                });
            }
            break;
        default:
    addLine(`Η εντολή δεν βρέθηκε: ${cmd}`, "error", 100);
    break;

    }
};


    const addLine = (text, style, time) => {
        setTimeout(() => {
            setOutput((prevOutput) => [...prevOutput, { text, style }]);
        }, time);
    };

    useEffect(() => {
        endOfOutputRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [output]);

    return (
        <div className="container" onClick={() => inputRef.current.focus()}>
            {/* Κυκλάκια */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div className="circle red"></div>
                <div className="circle orange"></div>
                <div className="circle green"></div>
            </div>
            
            <div className="terminal">
                <div className="history">
                    {output.map((line, index) => (
                        <p key={index} className={line.style} dangerouslySetInnerHTML={{ __html: line.text }} />
                    ))}
                    <div ref={endOfOutputRef} />
                </div>
                <div className="input-line">
                    <span className="prompt">
                        <i className="fas fa-heartbeat"></i>
                        <span>root@HellasDev</span>
                        <i className="fas fa-usd">:</i> 
                    </span>
                    <input   
                        ref={inputRef}
                        type="text"
                        className="input-terminal"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleInput}
                        autoFocus
                    />
                </div>
            </div>
        </div>
    );
    
}

export default App;